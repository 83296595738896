import Swiper from 'swiper';
import 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const initializeSliders = () => {

    const initializeSliderZitate = new Swiper('.zitat', {
        direction: 'horizontal',
        autoplay: {
            delay: 5000,
        },
        loop: true,
        slidesPerView: 1,
        initialSlide: 1,
        pagination: {
            el: '.swiper-pagination-zitat'
        },
    });

    const initializeSliderProtagonisten = new Swiper('.bilder', {
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        breakpoints: {
            769: {
                slidesPerView: 2,
                slidesPerGroup: 2
            }
        },
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const initializeSliderBio = new Swiper('.bio-bilder', {
        direction: 'horizontal',
        autoplay: {
            delay: 3000,
        },
        loop: true,
        slidesPerView: 1
    });

    const initializeSliderLogos = new Swiper('.logos-list', {
        direction: 'horizontal',
        autoplay: {
            delay: 1500,
        },
        loop: true,
        slidesPerView: 1,
        freeMode: true,
        breakpoints: {
            425: {
                slidesPerView: 2
            },
            769: {
                slidesPerView: 3
            },
            1024: {
                slidesPerView: 5
            }
        }
    });
};

export default initializeSliders;