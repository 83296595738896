// import Velocity from 'velocity-animate';

const initAnimations = () => {
    const aside = document.getElementsByClassName('aside')[0];
    const buch = document.getElementsByClassName('buch-img')[0];
    const trailer = document.getElementsByClassName('trailer-container')[0];
    const car = document.getElementsByClassName('auto-img')[0];
    const stempel = document.getElementsByClassName('stempel-img')[0];
    const protagonistNames = [...document.getElementsByClassName('protagonist-name')];
    const protagonists = [...document.getElementsByClassName('protagonist-img')];
    const frame = document.getElementsByClassName('frame-container')[0];

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0
    };

    const optionsSecondary = {
        root: null,
        rootMargin: '0px',
        threshold: 0.25
    };

    const asideAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                aside.classList.add('fade-in-left');
            } else {
                aside.classList.remove('fade-in-left');
            }
        })
    };

    const buchAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                buch.classList.add('fade-in-right');
            } else {
                buch.classList.remove('fade-in-right');
            }
        })
    };

    const trailerAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                trailer.classList.add('fade-in-bottom');
            } else {
                trailer.classList.remove('fade-in-bottom');
            }
        })
    };

    const carAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                car.classList.add('bounce-in-left');
            } else {
                car.classList.remove('bounce-in-left');
            }
        })
    };

    const stempelAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                stempel.classList.add('flip-in-diag-2-tl');
            } else {
                stempel.classList.remove('flip-in-diag-2-tl');
            }
        })
    };

    const protagonistNamesAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('bounce-in-top');
            }
        })
    };

    const protagonistsAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('flip-in-ver-right');
            }
        })
    };

    const frameAnim = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                frame.classList.add('fade-in-top');
            } else {
                frame.classList.remove('fade-in-top');
            }
        })
    };

    const observerAside = new IntersectionObserver(asideAnim, options);
    const observerBuch = new IntersectionObserver(buchAnim, options);
    const observerTrailer = new IntersectionObserver(trailerAnim, options);
    const observerCar = new IntersectionObserver(carAnim, options);
    const observerStempel = new IntersectionObserver(stempelAnim, optionsSecondary);
    const observerProtagonistNames = new IntersectionObserver(protagonistNamesAnim, optionsSecondary);
    const observerProtagonists = new IntersectionObserver(protagonistsAnim, optionsSecondary);
    const observerFrame = new IntersectionObserver(frameAnim, options);

    if (aside) {
        observerAside.observe(aside);
    }
    if (buch) {
        observerBuch.observe(buch);
    }
    if (trailer) {
        observerTrailer.observe(trailer);
    }
    if (car) {
        observerCar.observe(car);
    }
    if (stempel) {
        observerStempel.observe(stempel);
    }
    if (protagonistNames) {
        protagonistNames.map((protagonistName, i) => {
            if (window.innerWidth < 769) {
                if (i < 1) {
                    observerProtagonistNames.observe(protagonistNames[i]);
                }
            } else {
                if (i > 0 && i < 2) {
                    observerProtagonistNames.observe(protagonistNames[0]);
                    observerProtagonistNames.observe(protagonistNames[1]);
                }
            }
        });
    }
    if (protagonists) {
        protagonists.map((protagonist, i) => {
            if (window.innerWidth < 769) {
                if (i < 1) {
                    observerProtagonists.observe(protagonists[i]);
                }
            } else {
                if (i > 0 && i < 2) {
                    observerProtagonists.observe(protagonists[0]);
                    observerProtagonists.observe(protagonists[1]);
                }
            }
        });
    }
    if (frame) {
        observerFrame.observe(frame);
    }

};

const parallaxAnimations = () => {

    const currentScroll = window.pageYOffset;

    const tablettenFlascheAnimation = () => {
        var tablettenFlasche = document.getElementsByClassName("tablettenflasche")[0];
        var yPos = 0 - window.pageYOffset / 10;
        if (tablettenFlasche) {

            tablettenFlasche.style.top = 55 + yPos + "%";
            const checkpointTablettenFlasche = tablettenFlasche.offsetHeight + 300;
            var opacity;

            if (currentScroll <= checkpointTablettenFlasche) {
                opacity = currentScroll / checkpointTablettenFlasche;
            } else {
                opacity = 1;
            }

            tablettenFlasche.style.opacity = opacity;
        }
    }

    const tablettenAnimation = () => {
        var tabletten = document.getElementsByClassName("tabletten")[0];
        var yPos = 0 - window.pageYOffset / 20;
        if (tabletten) {
            tabletten.style.bottom = 60 + yPos + "%";
            const checkpointTabletten = tabletten.offsetHeight + 900;
            var opacity;

            if (currentScroll <= checkpointTabletten) {
                opacity = currentScroll / checkpointTabletten;
            } else {
                opacity = 1;
            }

            tabletten.style.opacity = opacity;
        }
    }

    const stempelAnimation = () => {
        var stempel = document.getElementsByClassName("stempel-img")[0];
        var yPos = 0 - window.pageYOffset / 22;
        if (stempel) {
            stempel.style.top = 125 + yPos + "%";
            const checkpointStempel = stempel.offsetHeight + 900;
            var opacity;

            if (currentScroll <= checkpointStempel) {
                opacity = currentScroll / checkpointStempel;
            } else {
                opacity = 1;
            }

            stempel.style.opacity = opacity;
        }
    }

    const palmeAnimation = () => {
        var palme = document.getElementsByClassName("palme-img")[0];
        var yPos = 50 - window.pageYOffset / 80;
        if (palme) {
            palme.style.top = 0 + yPos + "%";
            const checkpointPalme = palme.offsetHeight;
            var opacity;

            if (currentScroll <= checkpointPalme) {
                opacity = currentScroll / checkpointPalme;
            } else {
                opacity = 1;
            }

            palme.style.opacity = opacity;
        }
    }

    tablettenFlascheAnimation();
    tablettenAnimation();
    palmeAnimation();

    if (window.outerWidth >= 1024) {
        stempelAnimation();
    }
}

export { initAnimations, parallaxAnimations };
