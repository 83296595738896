require('./styles/index.scss');
import initializeSliders from './routes/sliders';
import { initAnimations, parallaxAnimations } from './routes/animations';

window.addEventListener('load', function () {
    initAnimations();
    initializeSliders();
});

window.addEventListener('scroll', function () {
    parallaxAnimations();
});


